import React from 'react'
import LayoutContainer from 'components/templates/premium/t/Layout'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import AvatarImg from 'assets/images/premium/t-bone/avatar-2.png'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import { toContentfulRaw } from 'utils/format'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Kate',
  firstLastName: 'Kate',
  nameToDisplay: 'Kate Lopez',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  businessProfileType: BusinessProfileType.Personal,
  template: PremiumTemplates.TStyle_Bone,
  metadata: {
    pageTitle: 'Professional TBone',
  },
  jobs: [
    {
      id: '1',
      title: 'Productora de Eventos',
    },
  ],
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
      },
    ],
    socialMedia: [
      {
        order: 1,
        id: '1',
        link: 'https://instagram.com/playu.shop',
        description: {
          description: 'Sígueme en Instragram',
        },
        type: SocialMedia.Instagram,
      },
      {
        order: 2,
        id: '2',
        link: '#',
        description: {
          description: 'Conectemos en LinkedIn',
        },
        type: SocialMedia.Linkedin,
      },
      {
        order: 3,
        id: '3',
        link: 'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU.',
        description: {
          description: 'Conversemos por Whatsapp',
        },
        type: SocialMedia.Whatsapp,
      },
      {
        order: 4,
        id: '4',
        link: 'https://www.facebook.com/playu.shop',
        description: {
          description: 'Sígueme en Facebook',
        },
        type: SocialMedia.Facebook,
      },
      {
        order: 5,
        id: '5',
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
        description: {
          description: 'Visita nuestro Canal',
        },
        type: SocialMedia.Youtube,
      },
      {
        order: 6,
        id: '6',
        link: 'https://www.tiktok.com/@playu.shop',
        description: {
          description: 'Sígueme en TikTok',
        },
        type: SocialMedia.Tiktok,
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'far fa-lightbulb',
      title: 'Engagement',
      description: {
        raw: toContentfulRaw(
          'Traer a un artista nacional o internacional ya no es más un problema. Me encargo del contacto con ellos mediante sus managers, asi como de los presupuestos y costos necesarios que ello puede involucrar.'
        ),
      },
    },
    {
      id: '2',
      icon: 'far fa-window-maximize',
      title: 'Marketing',
      description: {
        raw: toContentfulRaw(
          'Me encargo de la promoción de tu evento por diversas redes sociales y otros medios de comunicacion de acuerdo al presupuesto indicado.'
        ),
      },
    },
    {
      id: '3',
      icon: 'fas fa-award',
      title: 'Consultoria',
      description: {
        raw: toContentfulRaw(
          'Si no tienes idea de como poder realizar tu evento, me puedo encargar de todo ello. Puedo ver los temas de la logística necesaria hasta el alquiler del lugar para el evento.'
        ),
      },
    },
  ],
}

const PageComponent: React.FC = () => (
  <LayoutContainer user={new PremiumUser(defaultData)} template={PremiumTemplates.TStyle_Bone} enableColorSwitch />
)

export default PageComponent
